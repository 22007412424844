import React from 'react'
import { Field, useFormikContext } from 'formik';
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from 'react-intl';
import { SVFormControl, SVFormTextarea } from '_metronic/_partials/controls';



export function SpecialForm({ loading }) {

  const { formatMessage: intl } = useIntl();
  const { values: { notes } } = useFormikContext();

  return (
    <Row>
      <Col lg="4">

        <Field component={SVFormControl}
          {...{
            name: "discount",
            label: intl({ id: 'GENERAL.DISCOUNT' }) + " (%)",
            type: "number",
            max: 100,
            min: 0,
            step: "0.01",
            withFeedbackLabel: false,
            feedbackLabel: true,
            disabled: loading,
          }}
        />

      </Col>

      <Col lg="12">
        <Field component={SVFormTextarea}
          {...{
            name: 'notes',
            label: intl({ id: 'DOCUMENT.TABLE.COLUMN.NOTE' }),
            size: 'lg',
            withFeedbackLabel: false,
            feedbackLabel: notes?.length
              ? <span className={`${notes && notes?.length <= (1000 * 80 / 100) ? 'text-success' : notes && notes?.length <= 1000 ? 'text-warning' : 'text-danger'}`}>
                <FormattedMessage id="SETTINGS.DOCUMENT.REMAINING_CHARACTER" values={{ character: 1000 - notes?.length }} />
              </span>
              : true,
            disabled: loading,
          }}
        />
      </Col>

    </Row>
  )
}
