import React, { useEffect, useMemo, useRef } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls'
import { CreationAndUpdateInfo, BackButton, SaveButton } from '_metronic/_partials';
import { AdminRoutes } from 'constants/moduleRoutes'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AdminActions } from '../../_redux/actions';
import { useTempUserUIContext } from '../_context/TempUserUIContext';
import { INITIAL_TEMP_USER } from '../_context/TempUserUIHelpers';
import { General } from './general/General';
import Info from './info/Info';



export function TempUserEdit({ history, match: { params: { id } } }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const tempUsers = useSelector(state => state.admin?.temp_users);

  const tempUser = tempUsers?.find(user => user.id === id) || INITIAL_TEMP_USER;

  const UIContext = useTempUserUIContext();
  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
  }),
    [UIContext]
  );

  // if user id existed =>
  useEffect(() => {
    id && dispatch(AdminActions.getTempUsers(UIProps.queryParams))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  const handleSubmit = (values) => {
    const data = {
      id: values.id,
      name: values.name,
      surname: values.surname,
      email: values.email,
      newsletter: values.newsletter,
    }
    dispatch(AdminActions.updateTempUser(data));
    history.push(AdminRoutes.ADMIN_TEMP_USERS)
  }

  const btnRef = useRef();
  const submitForm = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  return (
    <Card className="card-box">

      <CardHeader
        back={<BackButton link={AdminRoutes.ADMIN_TEMP_USERS} />}
        title={intl({ id: 'ADMIN.TEMP_USER.EDIT' })}
        // info={<UserGuideHelper />}
        id='title_user_edit'
        sticky
      >
        <CardHeaderToolbar>
          <SaveButton onClick={submitForm} />
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody>
        <General initialValues={tempUser} btnRef={btnRef} handleSubmit={handleSubmit} />
        <Info tempUser={tempUser} />
      </CardBody>

      <CreationAndUpdateInfo data={tempUser} />

    </Card>
  )
}
