import React from 'react';
import { convertPriceCurrency } from '_metronic/_helpers';

export function PricingColumnFormatter(cell, row, rowIndex, { market_id }) {
  const shopValue = row?.inventories?.find((item) => item.market_id === market_id)?.shopValue;
  const newCell = shopValue?.pricing ?? cell.gross_price;

  if (shopValue?.variants?.length > 0 || (!shopValue && row.variants?.length > 0)) {
    return (
      <>
        <span className="label label-lg label-inline label-light-info ml-1">* ab</span>
        <span className="font-weight-bold">{convertPriceCurrency(+newCell, cell?.currency)}</span>
      </>
    );
  }
  return convertPriceCurrency(+newCell, cell?.currency);
}
