import React, { useContext, createContext, useEffect, useState, useCallback, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { VARIANT_ACTION_TYPES, initialNewVariants } from '../product-edit/variants/core/helpers';
import { ProductActions } from '../_redux/actions';
import { clone } from 'lodash';



export const ProductEditUIContext = createContext();
export const ProductEditUIConsumer = ProductEditUIContext.Consumer;
export const useProductEditUIContext = () => useContext(ProductEditUIContext);



export function ProductEditUIProvider({ children }) {

  const { productId } = useParams();
  const dispatch = useDispatch();

  const { productForEdit, currentVariants } = useSelector(state => ({
    productForEdit: state.products.productForEdit,
    currentVariants: state.products.variants,
  }));


  /*-------- NEW VARIANT COMPONENTS START ------*/
  const [newVariantsReducer, dispatchNewVariants] = useReducer((state, action) => {
    switch (action.type) {
      /**
       * @case OPEN_EDIT_VARIANT_DIALOG - open edit variant dialog
       * @case CLOSE_EDIT_VARIANT_DIALOG - close edit variant dialog
       */
      case VARIANT_ACTION_TYPES.OPEN_EDIT_VARIANT_DIALOG: {
        return {
          ...state,
          showEditVariantDialog: !state.showEditVariantDialog,
          variantId: action.payload?.variantId,
        };
      }
      case VARIANT_ACTION_TYPES.CLOSE_EDIT_VARIANT_DIALOG: {
        return {
          ...state,
          showEditVariantDialog: false,
          variantId: null,
        };
      }

      /**
       * @case OPEN_ADD_SINGLE_VARIANT_DIALOG -
       * @case CLOSE_ADD_SINGLE_VARIANT_DIALOG
       */
      case VARIANT_ACTION_TYPES.OPEN_ADD_SINGLE_VARIANT_DIALOG: {
        return {
          ...state,
          showAddSingleVariantDialog: !state.showAddSingleVariantDialog,
        };
      }
      case VARIANT_ACTION_TYPES.CLOSE_ADD_SINGLE_VARIANT_DIALOG: {
        return {
          ...state,
          showAddSingleVariantDialog: false,
        };
      }

      /**
       * @case OPEN_GENERATE_VARIANT_DIALOG - open generate variants dialog
       * @case CLOSE_GENERATE_VARIANT_DIALOG - close generate variants dialog
       */
      case VARIANT_ACTION_TYPES.OPEN_GENERATE_VARIANT_DIALOG: {
        return {
          ...state,
          showGenerateVariantDialog: !state.showGenerateVariantDialog,
        };
      }
      case VARIANT_ACTION_TYPES.CLOSE_GENERATE_VARIANT_DIALOG: {
        return {
          ...state,
          showGenerateVariantDialog: false,
        };
      }

      /**
       * ##### USELESS #####
       * @case SET_FORM_VALUES - set form values
       * @description set form values to state. When user change tax or vs.. than we need to update variants
       * @info We don't need to update variants. Because we are not using net price anymore
       */
      case VARIANT_ACTION_TYPES.SET_FORM_VALUES: {
        const { tax } = action.payload?.pricing;
        if (tax && tax !== state.formValues?.pricing?.tax) {
          const variants = clone(currentVariants);
          dispatch(ProductActions.setVariants(variants));
        }
        return {
          ...state,
          formValues: action.payload,
        };
      }

      default:
        return state;
    }
  }, initialNewVariants);
  /*-------- NEW VARIANT COMPONENTS END --------*/



  //------------- Feature Handler Start ---------------
  // useEffect(() => {
  //   //set feauteres
  //   if (productId && isArray(productForEdit.features) && productForEdit.features.length) {
  //     const initialFeaturesForEdit = productForEdit.features.map(feature => ({
  //       id: uniqueId('f_'),
  //       name: feature.name,
  //       value: feature.value,
  //     }));
  //     setFeatures(initialFeaturesForEdit);
  //   }
  // }, [productId, productForEdit.features]);

  const [features, setFeatures] = useState([]);
  const [featuresForEdit, setFeaturesForEdit] = useState(null);
  const [selectedFeatureId, setSelectedFeatureId] = useState(null);
  const [showEditFeatureDialog, setShowEditFeatureDialog] = useState(false);

  const openNewFeatureDialog = () => {
    setSelectedFeatureId(undefined);
    setShowEditFeatureDialog(true);
  };

  const openEditFeatureDialog = id => {
    setSelectedFeatureId(id);
    setShowEditFeatureDialog(true);
  };

  const closeEditFeatureDialog = () => {
    setSelectedFeatureId(undefined);
    setShowEditFeatureDialog(false);
  };

  const addFeatureHandler = feature => {
    setFeatures(prev => [...prev, feature]);
    setFeaturesForEdit(null);
  };

  const updateFeatureHandler = (id, feature) => {
    setFeatures(prev => {
      const currentFeatures = prev;
      const updatedFeaturesIndex = currentFeatures.findIndex(f => f.id === id);
      currentFeatures[updatedFeaturesIndex] = feature;
      return currentFeatures;
    });
    setFeaturesForEdit(null);
  };

  const deleteFeatureHandler = id => {
    setFeatures(prev => {
      const deletedFeatures = prev.filter(f => f.id !== id);
      return deletedFeatures;
    });
  };

  const getFeatureHandler = id => {
    const getFeature = features.find(f => f.id === id);
    setFeaturesForEdit(getFeature);
  };
  //------------------- Feature Handler End -------------------



  //------------------- Category Handler Start ---------------
  useEffect(() => {

    //set Category
    if (productId && productForEdit?.category?.id) {
      setSelectedKategorie({
        name: productForEdit.category.name,
        path: productForEdit.category.path,
        id: productForEdit.category.id,
        key: productForEdit.category.key,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productForEdit.category?.id, productForEdit.category?.path, productId]);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoriesRoute, setSelectedCategoriesRoute] = useState([]);
  const [selectedKategorie, setSelectedKategorie] = useState({});
  const [showCategorySelectDialog, setShowCategorySelectDialog] = useState(false);

  const handleOpenCategorySelectDialog = () => {
    setShowCategorySelectDialog(true);
    setSelectedCategoriesRoute([]);
  };

  const handleCloseCategorySelectDialog = () => {
    setShowCategorySelectDialog(false);
    setSelectedCategory(selectedCategoriesRoute[selectedCategoriesRoute.length - 1]);
  };

  const handleSetSelectedCategoriesRoute = useCallback(node => {
    setSelectedCategoriesRoute(prev => {
      const copyState = prev ? [...prev] : [];
      const updateState = [...copyState, { ...node }];
      return updateState;
    });
  }, []);


  //-------------------- Category Handler End -----------------------

  const [showServerRespondDialog, setShowServerRespondDialog] = useState(false);
  const handleOpenServerRespondDialog = () => setShowServerRespondDialog(true);
  const handleCloseServerRespondDialog = () => setShowServerRespondDialog(false);

  const [validateForm, setValidateFormBase] = useState([]);
  const setValidateForm = useCallback(keys => {
    setValidateFormBase(keys);
  }, []);



  //-------------------- Product Keys Handler Start -----------------------
  const [keyIds, setKeyIds] = useState([]);
  const [showImportProductKeys, setShowImportProductKeys] = useState(false);
  const openImportProductKeys = () => setShowImportProductKeys(true);
  const closeImportProductKeys = () => setShowImportProductKeys(false);

  //-------------------- Product Keys Handler End -------------------------

  //-------------------- Product Keys Delete Start -----------------------
  const [deleteKeyIds, setDeleteKeyIds] = useState([]);
  const [showDeleteProductKeys, setShowDeleteProductKeys] = useState(false);
  const openDeleteProductKeysDialog = (id) => {
    setDeleteKeyIds(id);
    setShowDeleteProductKeys(true)
  };
  const closeDeleteProductKeysDialog = () => {
    setShowDeleteProductKeys(false);
    setDeleteKeyIds(null);
  };

  //-------------------- Product Keys Delete End -------------------------


  //-------------------- Product Keys Add Single Start -----------------------
  const [showKeysAddDialog, setShowKeysAddDialog] = useState(false);
  const handleOpenKeysAddDialog = () => {
    setShowKeysAddDialog(true);
  };

  const handleCloseKeysAddDialog = () => {
    setShowKeysAddDialog(false);
  };

  //-------------------- Product Keys Add Single End -----------------------


  const value = {
    deleteKeyIds,
    keyIds,
    setKeyIds,

    validateForm,
    setValidateForm,

    features,
    featuresForEdit,
    selectedFeatureId,
    showEditFeatureDialog,
    openNewFeatureDialog,
    openEditFeatureDialog,
    closeEditFeatureDialog,
    addFeatureHandler,
    updateFeatureHandler,
    deleteFeatureHandler,
    getFeatureHandler,

    selectedCategory,
    setSelectedCategory,
    showCategorySelectDialog,
    handleCloseCategorySelectDialog,
    handleOpenCategorySelectDialog,
    selectedCategoriesRoute,
    setSelectedCategoriesRoute,
    handleSetSelectedCategoriesRoute,

    showKeysAddDialog,
    handleOpenKeysAddDialog,
    handleCloseKeysAddDialog,

    showServerRespondDialog,
    handleOpenServerRespondDialog,
    handleCloseServerRespondDialog,

    selectedKategorie,
    setSelectedKategorie,

    // New Variant Handler
    newVariantsReducer,
    dispatchNewVariants,

    showImportProductKeys,
    openImportProductKeys,
    closeImportProductKeys,

    showDeleteProductKeys,
    openDeleteProductKeysDialog,
    closeDeleteProductKeysDialog,
  };
  return (
    <ProductEditUIContext.Provider value={value}>
      {children}
    </ProductEditUIContext.Provider>
  )
}
