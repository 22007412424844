import React, { useMemo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { Modal, Button, Col, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Card, CardSubHeader, RemotePagination, SVInput } from '_metronic/_partials/controls';
import { SelectionCheckbox, convertPriceCurrency, headerSortingClasses, sortCaret } from '_metronic/_helpers';
import { initialSearchParams } from '../../../_context/DocumentsUIHelpers';
import { useDocumentEditUIContext } from '../../DocumentEditUIContext';
import { SettingsActions } from 'app/modules/settings/_redux/actions';
import { SelectCounter, UserInfoDropdown } from '_metronic/_partials';
import { ProductActions } from '../../../../products/_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { DocumentActions } from '../../../_redux/actions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as columnFormatters from './ColumnFormatter';
import { BASE_URL } from 'constants/apiUrl';
import { toast } from 'react-toastify';
import { Icon } from '_metronic/_icons';
import { isArray } from 'lodash';



export function ProductSelectDialog() {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();
  const inputRef = useRef();

  const [productIds, setProductIds] = useState([]);
  const [productCurrency, setProductCurrency] = useState([]);
  const [checked, setChecked] = useState(false);
  const [variantIds, setVariantIds] = useState([]);

  const [queryParams, setQueryParams] = useState({
    pagination: {
      page: 1,
      limit: 10
    },
    filter: null,
    order: 'id DESC',
    search: ''
  });

  const UIContext = useDocumentEditUIContext();
  const UIProps = useMemo(() => ({
    handleCloseProductSelectDialog: UIContext.handleCloseProductSelectDialog,
    handleOpenVariantSelectDialog: UIContext.handleOpenVariantSelectDialog,
    positionTableRowId: UIContext.positionTableRowId,
    productQueryParams: UIContext.productQueryParams,
    paginationParams: UIContext.paginationParams,
    setPaginationParams: UIContext.setPaginationParams,
    searchParams: UIContext.searchParams,
    setSearchParams: UIContext.setSearchParams,
    handleOpenProductEditDialog: UIContext.handleOpenProductEditDialog,
    showProductSelectDialog: UIContext.showProductSelectDialog,
    showProductEditDialog: UIContext.showProductEditDialog
  }),
    [UIContext]
  );

  const { products, countProducts, loading, positions } = useSelector((state) => ({
    products: state.products.entities,
    countProducts: state.products.countProducts,
    loading: state.products.loading,
    positions: state.documents.positions,
  }),
    shallowEqual
  );

  const onHideHandler = () => {
    UIProps.handleCloseProductSelectDialog();
    setProductIds([]);
    setVariantIds([]);
    setProductCurrency([]);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    return () => {
      UIProps.setSearchParams(initialSearchParams);
      setProductIds([]);
      setVariantIds([]);
      setProductCurrency([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.showProductSelectDialog]);

  useEffect(() => {
    let delayDebounceFn;
    if (queryParams.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(ProductActions.getProducts(queryParams));
      }, 350);
    } else {
      dispatch(ProductActions.getProducts(queryParams));
    }
    dispatch(SettingsActions.getUserSettingsByType('products'));
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [dispatch, UIProps.showProductEditDialog, queryParams]);


  const multipleSelectedProductHandler = (productIds, variantIds) => {
    if ((productCurrency.length > 0 && !productCurrency.every((value, index, array) => value === array[0])) || (positions?.[0] && !productCurrency.includes(positions[0]?.currency))) {
      toast.warning(<FormattedMessage id="DOCUMENT.POSITION.CURRENCY_WARNING" />)
      return;
    }

    if (isArray(productIds) && productIds.length > 0) {
      dispatch(DocumentActions.addMultiplePositions(productIds));
    }

    if (isArray(variantIds) && variantIds.length > 0) {
      // eslint-disable-next-line
      variantIds.map((item) => {
        dispatch(DocumentActions.addPosition(item.productId, item.variant));
      });
      setChecked(false);
    }
    onHideHandler();
  };

  const handleSearch = (e) => {
    const searchText = e.target.value;
    // UIProps.setSearchParams((prev) => ({ ...prev, searchText }));
    setQueryParams((prev) => ({ ...prev, search: searchText }));
  };

  const COLUMNS = [
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerClasses: 'text-nowrap',
      headerStyle: { minWidth: '260px', width: '60%' },
      classes: 'font-weight-bold pr-6',
      headerSortingClasses,
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50 symbol-light mr-4">
            <span className="symbol-label overflow-hidden">
              {row.images[0]?.thumbnail
                ? <img src={`${BASE_URL}/${row.images[0]?.thumbnail}`} className="h-100" alt={cell} />
                : <Icon.Image className="h-100 w-50" style={{ opacity: 0.3 }} />
              }
            </span>
          </div>
          <div>
            <div className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">
              {cell}
            </div>
            <div className="font-weight-bold text-dark-50">
              {row.sku}
            </div>
          </div>
        </div>
      ),
    },
    {
      dataField: 'category.name',
      text: intl({ id: 'CATEGORY.TITLE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerStyle: { width: '10%' },
      headerSortingClasses,
      formatter: (cell, row) => (cell &&
        <OverlayTrigger overlay={<Tooltip id="quick-category-tooltip">{row.category?.path}</Tooltip>}>
          <div className="label label-outline-warning label-inline">
            <span className="label label-dot label-warning mr-2" />
            <span className="font-weight-bold text-nowrap">{cell}</span>
          </div>
        </OverlayTrigger>
      ),
    },
    {
      dataField: 'quantity',
      formatter: columnFormatters.QuantityFormatter,
      text: intl({ id: 'PRODUCT.GENERAL.QUANTITY' }),
      headerClasses: 'text-center',
      headerStyle: { minWidth: '110px', width: '10%' },
      classes: 'text-center',
    },
    {
      dataField: 'net_price',
      formatter: columnFormatters.SPNetFormatter,
      text: intl({ id: 'PRODUCT.TABLE.PRICE_NET' }),
      headerClasses: 'text-right',
      headerStyle: { minWidth: '110px', width: '10%' },
      classes: 'text-right font-weight-bold',
    },
    {
      dataField: 'pricing.gross_price',
      formatter: columnFormatters.SPGrossFormatter,
      text: intl({ id: 'PRODUCT.TABLE.PRICE_GROSS' }),
      headerClasses: 'text-right pr-3',
      headerStyle: { minWidth: '130px', width: '10%' },
      classes: 'text-right font-weight-bold pr-3',
    }
  ];

  const handleOnSelect = (row, isSelect) => {
    if (isSelect && row?.variants?.length > 0) {
      return false;
    }
    return true;
  };

  const getNonSelectableRows = (prods) => {
    if (!isArray(prods) && prods.length === 0) {
      return [];
    }
    const indexes = prods.map((p, i) => (p.variants.length > 0 ? i : null)).filter((i) => i !== null);
    return indexes;
  };

  const allCurrency = (currency) => {
    const currencyIndex = productCurrency.indexOf(currency);
    if (currencyIndex !== -1) {
      const updatedProductCurrency = [...productCurrency];
      updatedProductCurrency.splice(currencyIndex, 1);
      setProductCurrency(updatedProductCurrency);
    }
  };

  const onChange = ({ ids, setIds, rowId, currency }) => {
    if (ids.some((id) => id === rowId)) {
      setIds(ids.filter((id) => id !== rowId));
      allCurrency(currency)
    } else {
      const newIds = [...ids];
      newIds.push(rowId);
      setIds(newIds);
      const newCurrency = [...productCurrency];
      newCurrency.push(currency);
      setProductCurrency(newCurrency);
    }
  };

  const selectRow = ({ entities, ids, setIds }) => {
    return {
      mode: 'checkbox',
      clickToSelect: true,
      hideSelectAll: true,
      clickToExpand: true,
      nonSelectable: getNonSelectableRows(entities),

      selectionRenderer: ({ rowKey }) => {
        const row = entities.find((ent) => ent.id === rowKey);
        const isSelected = ids.some((el) => el === rowKey);
        const checkProduct = (row) => {
          const isDigitalProduct = row.category.key === 'digital_product' && row.quantity <= 0;
          if (!isSelected && positions.some((pos) => pos.productId === rowKey)) {
            return toast.warning(intl({ id: 'DOCUMENT.POSITION.ALREADY_AVAILABLE' }, { name: row.name }))
          } else if (!isSelected && row.quantity <= 0) {
            toast.warning(intl({ id: isDigitalProduct ? 'DOCUMENT.POSITION.IS_VALID.QUANTITY5' : 'DOCUMENT.POSITION.IS_VALID.QUANTITY2' }))
          }
          !isDigitalProduct && onChange({ ids, setIds, rowId: rowKey, currency: row.pricing.currency })
        }
        if (row.variants.length > 0) {
          return (
            <Button
              className="label label-inline label-light-primary px-1"
              style={{ border: '1px solid' }}
              onClick={(e) => e.currentTarget.parentNode.parentNode.lastChild.click()}
              id="btn_return"
            >
              <Icon.ArrowReturnRight />
            </Button>
          );
        }
        return (
          <SelectionCheckbox
            isSelected={isSelected}
            onChange={() => {
              checkProduct(row);
              // row.quantity <= 0 ? toast.warning(intl({ id: 'DOCUMENT.POSITION.IS_VALID.QUANTITY1' })) : onChange({ ids, setIds, rowId: row.id, currency: row.pricing.currency });;
            }}
            id={row?.id}
          />
        );
      },
      onSelect: handleOnSelect
    };
  };

  const rowStyle = (row, index) => {
    if (row.variants.length > 0) {
      return { cursor: 'pointer' };
    }
  };

  // const columnsVariant = ['','Farbe','Größe','andere','sku','Menge','VERKAUFSPREIS','VERKAUFSPREIS BRUTTO',"ean"];

  const onChangeVariant = (e, product, variant) => {
    const { checked } = e.target;
    setChecked(checked);

    if (variantIds.every((par) => par.variantId !== variant) && checked) {
      setVariantIds([...variantIds, { productId: product.id, variant }]);
      const newCurrency = [...productCurrency];
      newCurrency.push(product?.pricing?.currency);
      setProductCurrency(newCurrency);
    } else if (!checked) {
      setVariantIds(variantIds.filter((par) => par.variant !== variant));
      allCurrency(product?.pricing?.currency)
    }
  };

  const expandRow = {
    nonExpandable: products.filter((item) => item.variants.length === 0).map((item) => item.id),
    //parentClassName: 'bg-light',
    renderer: (row) =>
      row.variants.length > 0 && (
        <div className="pl-9">
          <Table className="mb-0" style={{ backgroundColor: "#f9f9f9b3" }}>
            <tbody>
              {row.variants.map((item, i) => (
                <tr style={{ height: '40px' }} key={i}>

                  <td style={{ width: '30px' }} onClick={(e) => {
                    if (positions.some((pos) => pos.sku === item.sku)) {
                      toast.warning(intl({ id: 'DOCUMENT.POSITION.ALREADY_AVAILABLE' }, { name: item.sku }));
                      e.preventDefault();
                    } else if (!checked && e.target.tagName === 'SPAN' && item.quantity <= 0) {
                      toast.warning(intl({ id: 'DOCUMENT.POSITION.IS_VALID.QUANTITY2' }));
                    }
                  }}>
                    <SelectionCheckbox
                      type="checkbox"
                      onChange={(e) => onChangeVariant(e, row, item)}
                      defaultChecked={checked}
                      /* disabled={item.quantity <= 0}  */
                      id={item?.sku}
                    />
                  </td>

                  <td style={{ minWidth: '220px' }} className="d-flex align-items-center">
                    <div className="symbol symbol-50 symbol-light mr-4">
                      <span className="symbol-label overflow-hidden">
                        {row.images[0]?.thumbnail
                          ? <img src={`${BASE_URL}/${row.images[0]?.thumbnail}`} className="h-100" alt={item.specs.map((spec) => spec.value).join(' ')} />
                          : <Icon.Image className="h-100 w-50" style={{ opacity: 0.3 }} />
                        }
                      </span>
                    </div>

                    <div>
                      <div className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">
                        {item.specs.map((spec) => spec.value).join(' ')}
                      </div>
                      <div className="font-weight-bold text-dark-50">
                        {row.sku}
                      </div>
                    </div>
                  </td>

                  <td style={{ width: '110px' }} className={`text-center ${item.quantity <= 0 ? 'text-danger' : ''}`}>
                    {columnFormatters.QuantityFormatter(item.quantity, item)}
                  </td>

                  <td style={{ width: '110px' }} className="text-right font-weight-bold">
                    {convertPriceCurrency((+item.pricing?.gross_price / (1 + +row.pricing?.tax / 100)), row?.pricing?.currency)}
                  </td>

                  <td style={{ width: '127px', paddingRight: '6px' }} className="text-right font-weight-bold">
                    {convertPriceCurrency(+item.pricing?.gross_price, row?.pricing?.currency)}
                  </td>

                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ),
    onSelect: handleOnSelect
  };


  return (
    <Modal show={UIProps.showProductSelectDialog} onHide={onHideHandler} backdrop="static" keyboard={false} size="xl" centered>
      <Card>

        <CardSubHeader
          icon={<Icon.Catalog />}
          title={<FormattedMessage id="DOCUMENT.POSITION.PRODUCTS_TITLE" />}
          info={<UserInfoDropdown description={<FormattedMessage id='DOCUMENT.POSITION_ADD.PRODUCT_ADD_INFO' />} />}
        >
          <Col md="4" className='pr-0'>
            <SVInput
              type="search"
              name="searchText"
              placeholder={intl({ id: 'GENERAL.SEARCH' })}
              onChange={handleSearch}
              value={queryParams.search}
              ref={inputRef}
              autoComplete="off"
              size="sm"
            />
          </Col>
        </CardSubHeader>

        <Modal.Body>

          <PerfectScrollbar className="scroll" style={{ maxHeight: "calc(90vh - 200px)", minHeight: "200px", position: "relative" }}>
            <BootstrapTable
              keyField="id"
              key={productIds}
              data={products || []}
              columns={COLUMNS}
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-vertical-center"
              bootstrap4
              bordered={false}
              condensed
              noDataIndication={intl({ id: 'GENERAL.NO_RESULT' })}
              selectRow={selectRow({
                ids: productIds,
                setIds: setProductIds,
                entities: products
              })}
              rowStyle={rowStyle}
              expandRow={expandRow}
            // rowEvents={rowEvents}
            />
          </PerfectScrollbar>

          <RemotePagination
            queryParams={queryParams.pagination}
            setQueryParams={(data) => setQueryParams((prev) => ({ ...prev, pagination: { ...prev.pagination, ...data } }))}
            totalCount={countProducts}
            entitiesCount={products?.length}
            loading={loading}
            countForPagination={countProducts}
          />

        </Modal.Body>

        <Modal.Footer>

          <SelectCounter count={productIds.length + variantIds.length} />

          <Button variant="light" onClick={onHideHandler} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="primary" className="ml-2" onClick={() => multipleSelectedProductHandler(productIds, variantIds)} disabled={!productIds.length && !variantIds.length} id="btn_add_modal">
            <FormattedMessage id="GENERAL.ADD" />
          </Button>

        </Modal.Footer>
      </Card>
    </Modal>
  );
}
