import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import BootstrapTable from 'react-bootstrap-table-next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NoRecordsFoundMessage, convertPriceCurrency, getSelectRow } from '_metronic/_helpers';
import { getShipperName } from 'app/modules/documents/_context/DocumentsUIHelpers';
import { useUserSettingsUIContext } from '../../_context/UserSettingsUIContext';
import { getConnection } from 'constants/connectionSettings';
import { DeleteConfirm } from '_metronic/_partials/dialogs';
import { SettingsActions } from '../../_redux/actions';
import { Icon } from '_metronic/_icons';



export function ShippingSettingsTable({ id, settings_type, searchedShipping, default_shipping, loading, setSearchQuery }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const UIContext = useUserSettingsUIContext();
  const UIProps = useMemo(() => ({
    openShippingDeleteDialog: UIContext.openShippingDeleteDialog,
    showDeleteShippingDialog: UIContext.showDeleteShippingDialog,
    closeDeleteShippingDialog: UIContext.closeDeleteShippingDialog,
    openShippingSettingDialog: UIContext.openShippingSettingDialog,
    shippingId: UIContext.shippingId,
    setIds: UIContext.setIds,
    ids: UIContext.ids,
  }), [UIContext]);

  const deleteShippingSettings = () => {
    let filteredShipping
    if (UIProps.ids.length > 0 && !UIProps.shippingId) {
      filteredShipping = default_shipping.filter(item => !UIProps.ids.includes(item.id));
    } else {
      filteredShipping = default_shipping.filter(item => item.id !== UIProps.shippingId);
    }

    if (id && settings_type === "shipping") {
      dispatch(SettingsActions.patchUserSettings(id, { default_shipping: filteredShipping }))
    }

    UIProps.setIds([]);
    setSearchQuery('');
  }


  const COLUMNS = [
    {
      dataField: 'service',
      text: intl({ id: 'GENERAL.SHIPPING' }),
      headerStyle: { minWidth: '100px', width: "25%" },
      headerClasses: 'text-left',
      classes: 'text-left font-weight-bold text-dark-75',
      formatter: (cell, row) => {
        const getService = getShipperName(row.service)
        const icon = getConnection(row?.service)?.icon
        return (
          <div type="button" className="d-flex align-items-center text-muted text-hover-primary preview" onClick={() => UIProps.openShippingSettingDialog(row?.id)}>
            <div className="d-flex label-outline-primary mr-4" style={{ fontSize: "2.5rem" }}>
              {(icon && !row?.unlabeled)
                ? icon
                : <Icon.SupplierFIll />
              }
            </div>
            <div>
              <div className="d-flex align-items-center font-weight-bolder font-size-lg text-dark-75">
                {getService}
                <span className='d-flex invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
                  <Icon.Pencil />
                </span>
              </div>
              <div className="font-weight-bold text-dark-50">
                {row.dp_product_name ? row.dp_product_name : row.unlabeled ? intl({ id: 'SETTINGS.DOCUMENT.SHIPPING.WITH_TRACKING_ID' }) : intl({ id: 'SETTINGS.DOCUMENT.SHIPPING.WITH_API' })}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      dataField: 'profile_name',
      text: intl({ id: 'SETTINGS.DOCUMENT.SHIPPING.PROFILE_NAME' }),
      headerStyle: { minWidth: '100px', width: "20%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-75',
      editorStyle: (row) => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "center" }),
    },
    {
      dataField: 'type',
      text: "Type",
      headerStyle: { minWidth: '50px', width: "10%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-75',
      formatter: (cell, row, rowIndex) => (cell === "shipment" ? intl({ id: 'DOCUMENT.SHIPPING.TYPE_SHIPMENT' }) : intl({ id: 'DOCUMENT.SHIPPING.TYPE_RETURN' })),
    },
    {
      dataField: 'weight',
      text: intl({ id: 'GENERAL.WEIGHT' }),
      headerStyle: { minWidth: '100px', width: "10%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold font-size-lg text-dark-75',
      editorStyle: (row) => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "center" }),
    },
    {
      dataField: 'price',
      text: intl({ id: 'DOCUMENT.SHIPPING_PRICE' }),
      headerStyle: { minWidth: '140px', width: "10%" },
      headerClasses: 'text-right',
      classes: 'text-right font-weight-bolder text-dark-75',
      formatter: (cell, row) => (convertPriceCurrency(cell)),
    },
    {
      dataField: "default",
      text: intl({ id: "SETTINGS.DOCUMENT.SHIPPING.DEFAULT" }),
      headerStyle: { minWidth: '100px', width: "5%" },
      headerClasses: "text-center",
      classes: "font-weight-bold text-center",
      formatter: (cell, row) =>
        <span className="svg-icon svg-icon-md">
          {row?.default
            ? <Icon.CheckCircleFill className="text-success" />
            : null
          }
        </span>
    },
    {
      dataField: 'action',
      text: "actions",
      headerStyle: { minWidth: '80px', width: "7%" },
      headerClasses: 'text-center',
      classes: 'text-center',
      editable: false,
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
              <div className="btn btn-icon btn-hover-primary btn-sm" onClick={() => UIProps.openShippingSettingDialog(row?.id)} id={`btn_edit_${row.id}`}>
                <span className="svg-icon svg-icon-md">
                  <Icon.PencilSquare />
                </span>
              </div>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip id="shipping-delete-tooltip"><FormattedMessage id="DOCUMENT.SHIPPING.DELETE" /></Tooltip>}>
              <div className="btn btn-icon btn-hover-danger btn-sm" onClick={() => UIProps.openShippingDeleteDialog(row?.id)} id={`btn_delete_${row.id}`}>
                <span className="svg-icon svg-icon-md">
                  <Icon.Trash />
                </span>
              </div>
            </OverlayTrigger>
          </>
        )
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        data={searchedShipping || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden mb-0"
        headerClasses="bg-info-o-10"
        bootstrap4
        bordered={false}
        deleteRow={true}
        condensed
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => setSearchQuery('')} />}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: searchedShipping,
        })}
      />

      <DeleteConfirm show={UIProps.showDeleteShippingDialog} onHide={UIProps.closeDeleteShippingDialog} toDelete={() => { deleteShippingSettings(); UIProps.closeDeleteShippingDialog() }} />
    </>
  )
}

