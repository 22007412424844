import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormattedMessage, useIntl } from 'react-intl';
import { DocumentActions } from '../_redux/actions';
import { Button, Modal } from 'react-bootstrap';
import { sortCaret, headerSortingClasses } from '_metronic/_helpers';
import { CloseButton } from '_metronic/_partials';
import { BASE_URL } from 'constants/apiUrl';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';
import { CreditWarningDialog } from './CreditWarningDialog';



export function StockControlDialog({ show, onHide, data }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const [showWarning, setShowWarning] = useState(false);

  const { convertDocument, convertParams } = data;


  const handleConfirm = (isStockUpdate) => {
    if (result) {
      setShowWarning(true);
    } else {
      confirmDispatch(isStockUpdate);
    }
  };

  const confirmDispatch = (isStockUpdate) => {
    convertDocument.is_stock_update = isStockUpdate;
    dispatch(DocumentActions.createDocument(convertDocument, convertParams));
    onHide();
  };



  const result = convertDocument?.position?.some(pos => pos.digital_product?.some(item => item.is_key_sent) );

  const COLUMNS = [
    {
      dataField: 'title',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerStyle: { minWidth: '120px', width: "80%" },
      headerClasses: 'text-nowrap pl-10',
      classes: 'font-weight-bold pl-10',
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50 symbol-light mr-4">
            <span className="symbol-label overflow-hidden">
              {/* FIXME: Positions icine images koyunca bu kisim calicasak */}
              {row?.images?.length > 0
                ? <img src={`${BASE_URL}/${row?.images[0]?.thumbnail}`} className="h-100" alt={cell} />
                : <Icon.Image className="h-100 w-50" style={{ opacity: 0.3 }} />
              }
            </span>
          </div>
          <div>
            <div className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">
              {cell}
            </div>
            <div className="font-weight-bold text-dark-50">
              {row.sku}
            </div>
          </div>
        </div>
      )
    },
    {
      dataField: 'quantity',
      text: intl({ id: 'PRODUCT.GENERAL.QUANTITY' }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      align: 'center',
      headerClasses: 'text-nowrap text-right pr-10',
      classes: 'font-weight-bolder font-size-lg pr-6'
    }
  ];


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} aria-labelledby="example-modal-sizes-title-md" size="lg" centered>

        <Modal.Header>
          <Modal.Title>
            <Icon.Layers className="mr-3" />
            <FormattedMessage id="PRODUCT.SHOPS_QUANTITY.TITLE" />
          </Modal.Title>
          <CloseButton onClick={onHide} />
        </Modal.Header>

        <Modal.Body className='p-0'>

          <BootstrapTable
            keyField="id"
            data={convertDocument?.position || []}
            columns={COLUMNS}
            wrapperClasses="table-responsive"
            headerClasses="bg-info-o-10"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            bordered={false}
            condensed
          />

        </Modal.Body>

        <Modal.Footer>

          <Button variant='light-danger' onClick={() => handleConfirm(false)} id="btn_not_update">
            <FormattedMessage id="GENERAL.STOCK_NO_UPDATE" />
          </Button>

          <Button variant='primary' className='ml-2' onClick={() => handleConfirm(true)} id="btn_update">
            <FormattedMessage id="GENERAL.STOCK_UPDATE" />
          </Button>

        </Modal.Footer>

      </Modal>

      <CreditWarningDialog
        show={showWarning}
        onHide={() => setShowWarning(false)}
        onConfirm={() => confirmDispatch(showWarning)}
      />
    </Portal>
  );
}