import React from "react";
import { FormattedMessage } from "react-intl";



export const addressType = [
  {
    value: "address_delivery",
    label: <FormattedMessage id="ADDRESS.DELIVERY" />,
  },
  {
    value: "address_invoice",
    label: <FormattedMessage id="ADDRESS.INVOICE" />,
  }
];

export const salutation = [
  {
    value: "man",
    label: <FormattedMessage id="ADDRESS.MAN" />,
  },
  {
    value: "woman",
    label: <FormattedMessage id="ADDRESS.WOMAN" />,
  },
  {
    value: "family",
    label: <FormattedMessage id="ADDRESS.FAMILY" />,
  },
  {
    value: "company",
    label: <FormattedMessage id="GENERAL.COMPANY" />,
  }
];

export const COUNTRY_DE = [
  { value: 'AD', label: 'Andorra' },
  { value: 'AF', label: 'Afghanistan' },
  { value: 'EG', label: 'Ägypten' },
  { value: 'AX', label: 'Åland' },
  { value: 'AL', label: 'Albanien' },
  { value: 'DZ', label: 'Algerien' },
  { value: 'VI', label: 'Amerikanische Jungferninseln' },
  { value: 'UM', label: 'Amerikanisch-Ozeanien' },
  { value: 'AS', label: 'Amerikanisch-Samoa' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarktis' },
  { value: 'AG', label: 'Antigua und Barbuda' },
  { value: 'GQ', label: 'Äquatorialguinea' },
  { value: 'AR', label: 'Argentinien' },
  { value: 'AM', label: 'Armenien' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AZ', label: 'Aserbaidschan' },
  { value: 'ET', label: 'Äthiopien' },
  { value: 'AU', label: 'Australien' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesch' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus (Weißrussland)' },
  { value: 'BE', label: 'Belgien' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivien' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius und Saba' },
  { value: 'BA', label: 'Bosnien und Herzegowina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvetinsel' },
  { value: 'BR', label: 'Brasilien' },
  { value: 'VG', label: 'Britische Jungferninseln' },
  { value: 'IO', label: 'Britisches Territorium im Indischen Ozean' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgarien' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China, Volksrepublik' },
  { value: 'CK', label: 'Cookinseln' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'DK', label: 'Dänemark' },
  { value: 'DE', label: 'Deutschland' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominikanische Republik' },
  { value: 'DJ', label: 'Dschibuti' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'CI', label: 'Elfenbeinküste' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estland (Reval)' },
  { value: 'FK', label: 'Falklandinseln (Malwinen)' },
  { value: 'FO', label: 'Färöer' },
  { value: 'FJ', label: 'Fidschi' },
  { value: 'FI', label: 'Finnland' },
  { value: 'FR', label: 'Frankreich' },
  { value: 'TF', label: 'Französische Süd- und Antarktisgebiete' },
  { value: 'GF', label: 'Französisch-Guayana' },
  { value: 'PF', label: 'Französisch-Polynesien' },
  { value: 'GA', label: 'Gabun' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgien' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GR', label: 'Griechenland' },
  { value: 'GB', label: 'Großbritannien' },
  { value: 'GL', label: 'Grönland' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey (Kanalinsel)' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard- und McDonald-Inseln' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hongkong' },
  { value: 'IN', label: 'Indien' },
  { value: 'ID', label: 'Indonesien' },
  { value: 'IM', label: 'Insel Man' },
  { value: 'IQ', label: 'Irak' },
  { value: 'IR', label: 'Iran' },
  { value: 'IE', label: 'Irland' },
  { value: 'IS', label: 'Island' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italien' },
  { value: 'JM', label: 'Jamaika' },
  { value: 'JP', label: 'Japan' },
  { value: 'YE', label: 'Jemen' },
  { value: 'JE', label: 'Jersey (Kanalinsel)' },
  { value: 'JO', label: 'Jordanien' },
  { value: 'KY', label: 'Kaimaninseln' },
  { value: 'KH', label: 'Kambodscha' },
  { value: 'CM', label: 'Kamerun' },
  { value: 'CA', label: 'Kanada' },
  { value: 'CV', label: 'Kap Verde' },
  { value: 'KZ', label: 'Kasachstan' },
  { value: 'QA', label: 'Katar' },
  { value: 'KE', label: 'Kenia' },
  { value: 'KG', label: 'Kirgisistan' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'CC', label: 'Kokosinseln (Keelinginseln)' },
  { value: 'CO', label: 'Kolumbien' },
  { value: 'KM', label: 'Komoren' },
  { value: 'CD', label: 'Kongo' },
  { value: 'HR', label: 'Kroatien' },
  { value: 'CU', label: 'Kuba' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'LA', label: 'Laos' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LV', label: 'Lettland' },
  { value: 'LB', label: 'Libanon' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libyen' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Litauen' },
  { value: 'LU', label: 'Luxemburg' },
  { value: 'MO', label: 'Macau' },
  { value: 'MG', label: 'Madagaskar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Malediven' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MA', label: 'Marokko' },
  { value: 'MH', label: 'Marshallinseln' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauretanien' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MK', label: 'Mazedonien' },
  { value: 'MX', label: 'Mexiko' },
  { value: 'FM', label: 'Mikronesien' },
  { value: 'MD', label: 'Moldawien' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolei' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MZ', label: 'Mosambik' },
  { value: 'MM', label: 'Myanmar (Burma)' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NC', label: 'Neukaledonien' },
  { value: 'NZ', label: 'Neuseeland' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NL', label: 'Niederlande' },
  { value: 'AN', label: 'Niederländische Antillen' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'KP', label: 'Nordkorea' },
  { value: 'NF', label: 'Norfolkinsel' },
  { value: 'NO', label: 'Norwegen' },
  { value: 'MP', label: 'Nördliche Marianen' },
  { value: 'OM', label: 'Oman' },
  { value: 'AT', label: 'Österreich' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PS', label: 'Palästina' },
  { value: 'PW', label: 'Palau' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua-Neuguinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippinen' },
  { value: 'PN', label: 'Pitcairninseln' },
  { value: 'PL', label: 'Polen' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'CG', label: 'Republik Kongo' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RW', label: 'Ruanda' },
  { value: 'RO', label: 'Rumänien' },
  { value: 'RU', label: 'Russische Föderation' },
  { value: 'BL', label: 'Saint-Barthélemy' },
  { value: 'MF', label: 'Saint-Martin (franz. Teil)' },
  { value: 'SB', label: 'Salomonen' },
  { value: 'ZM', label: 'Sambia' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'São Tomé und Príncipe' },
  { value: 'SA', label: 'Saudi-Arabien' },
  { value: 'SE', label: 'Schweden' },
  { value: 'CH', label: 'Schweiz' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbien' },
  { value: 'SC', label: 'Seychellen' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'ZW', label: 'Simbabwe' },
  { value: 'SG', label: 'Singapur' },
  { value: 'SX', label: 'Sint Maarten (niederl. Teil)' },
  { value: 'SK', label: 'Slowakei' },
  { value: 'SI', label: 'Slowenien' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ES', label: 'Spanien' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'KN', label: 'St. Kitts und Nevis' },
  { value: 'LC', label: 'St. Lucia' },
  { value: 'PM', label: 'St. Pierre und Miquelon' },
  { value: 'SH', label: 'St. Helena' },
  { value: 'VC', label: 'St. Vincent und die Grenadinen' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SS', label: 'Sudsudan!Südsudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'ZA', label: 'Südafrika' },
  { value: 'GS', label: 'Südgeorgien und die Südl. Sandwichinseln' },
  { value: 'KR', label: 'Südkorea' },
  { value: 'SJ', label: 'Svalbard und Jan Mayen' },
  { value: 'SZ', label: 'Swasiland' },
  { value: 'SY', label: 'Syrien' },
  { value: 'TJ', label: 'Tadschikistan' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TZ', label: 'Tansania' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad und Tobago' },
  { value: 'TD', label: 'Tschad' },
  { value: 'CZ', label: 'Tschechische Republik' },
  { value: 'TN', label: 'Tunesien' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks- und Caicosinseln' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'TR', label: 'Türkei' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'HU', label: 'Ungarn' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Usbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VA', label: 'Vatikanstadt' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'AE', label: 'Vereinigte Arabische Emirate' },
  { value: 'US', label: 'Vereinigte Staaten von Amerika' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'WF', label: 'Wallis und Futuna' },
  { value: 'CX', label: 'Weihnachtsinsel' },
  { value: 'EH', label: 'Westsahara' },
  { value: 'CF', label: 'Zentralafrikanische Republik' },
  { value: 'CY', label: 'Zypern' },
]

export const COUNTRY_EN = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Åland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia, Plurinational State of' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo, the Democratic Republic of the' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: 'Côte d\'Ivoire' },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Mcdonald Islands' },
  { value: 'VA', label: 'Holy See (Vatican City State)' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran, Islamic Republic of' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: 'Korea, Democratic People\'s Republic of' },
  { value: 'KR', label: 'Korea, Republic of' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: 'Lao People\'s Democratic Republic' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao' },
  { value: 'MK', label: 'Macedonia, the Former Yugoslav Republic of' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia, Federated States of' },
  { value: 'MD', label: 'Moldova, Republic of' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestine, State of' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russian Federation' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (French part)' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SZ', label: 'Swaziland' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syrian Arab Republic' },
  { value: 'TW', label: 'Taiwan, Province of China' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania, United Republic of' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela, Bolivarian Republic of' },
  { value: 'VN', label: 'Viet Nam' },
  { value: 'VG', label: 'Virgin Islands, British' },
  { value: 'VI', label: 'Virgin Islands, U.S.' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
]

export const COUNTRY_TR = [
  { value: 'AF', label: 'Afganistan' },
  { value: 'DE', label: 'Almanya' },
  { value: 'US', label: 'Amerika Birleşik Devletleri' },
  { value: 'AS', label: 'Amerikan Samoası' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarktika' },
  { value: 'AG', label: 'Antigua ve Barbuda' },
  { value: 'AR', label: 'Arjantin' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AZ', label: 'Azerbaycan' },
  { value: 'BS', label: 'Bahamalar' },
  { value: 'BH', label: 'Bahreyn' },
  { value: 'BD', label: 'Bangladeş' },
  { value: 'BB', label: 'Barbados' },
  { value: 'EH', label: 'Batı Sahra' },
  { value: 'BE', label: 'Belçika' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BY', label: 'Beyaz Rusya' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'AE', label: 'Birleşik Arap Emirlikleri' },
  { value: 'GB', label: 'Birleşik Krallık' },
  { value: 'BO', label: 'Bolivya, Çokuluslu Devleti' },
  { value: 'BA', label: 'Bosna Hersek' },
  { value: 'BW', label: 'Botsvana' },
  { value: 'BR', label: 'Brezilya' },
  { value: 'BN', label: 'Brunei Sultanlığı' },
  { value: 'BG', label: 'Bulgaristan' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'GI', label: 'Cebelitarık' },
  { value: 'DZ', label: 'Cezayir' },
  { value: 'KH', label: 'Kamboçya' },
  { value: 'CM', label: 'Kamerun' },
  { value: 'CA', label: 'Kanada' },
  { value: 'QA', label: 'Katar' },
  { value: 'KY', label: 'Kayman Adaları' },
  { value: 'KZ', label: 'Kazakistan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'CY', label: 'Kıbrıs' },
  { value: 'KG', label: 'Kırgızistan' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'CC', label: 'Kokos (Keeling) Adaları' },
  { value: 'CO', label: 'Kolombiya' },
  { value: 'KM', label: 'Komorlar' },
  { value: 'CG', label: 'Kongo' },
  { value: 'CD', label: 'Kongo Demokratik Cumhuriyeti' },
  { value: 'KP', label: 'Kore Demokratik Halk Cumhuriyeti' },
  { value: 'KR', label: 'Kore Cumhuriyeti' },
  { value: 'CR', label: 'Kosta Rika' },
  { value: 'KW', label: 'Kuveyt' },
  { value: 'CU', label: 'Küba' },
  { value: 'LA', label: 'Laos Demokratik Halk Cumhuriyeti' },
  { value: 'LS', label: 'Lesoto' },
  { value: 'LV', label: 'Letonya' },
  { value: 'LR', label: 'Liberya' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Litvanya' },
  { value: 'LU', label: 'Lüksemburg' },
  { value: 'MO', label: 'Makao' },
  { value: 'MK', label: 'Makedonya, Eski Yugoslav Cumhuriyeti' },
  { value: 'MG', label: 'Madagaskar' },
  { value: 'MW', label: 'Malavi' },
  { value: 'MV', label: 'Maldivler' },
  { value: 'MY', label: 'Malezya' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'IM', label: 'Man Adası' },
  { value: 'MH', label: 'Marshall Adaları' },
  { value: 'MQ', label: 'Martinik' },
  { value: 'MR', label: 'Moritanya' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Meksika' },
  { value: 'FM', label: 'Mikronezya, Federal Devletleri' },
  { value: 'MD', label: 'Moldova Cumhuriyeti' },
  { value: 'MC', label: 'Monako' },
  { value: 'MN', label: 'Moğolistan' },
  { value: 'ME', label: 'Karadağ' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MZ', label: 'Mozambik' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibya' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NE', label: 'Nijer' },
  { value: 'NG', label: 'Nijerya' },
  { value: 'NU', label: 'Niue' },
  { value: 'NI', label: 'Nikaragua' },
  { value: 'NO', label: 'Norveç' },
  { value: 'NF', label: 'Norfolk Adası' },
  { value: 'MP', label: 'Kuzey Mariana Adaları' },
  { value: 'OM', label: 'Umman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua Yeni Gine' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Polonya' },
  { value: 'PT', label: 'Portekiz' },
  { value: 'PR', label: 'Porto Riko' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RO', label: 'Romanya' },
  { value: 'RW', label: 'Ruanda' },
  { value: 'RU', label: 'Rusya Federasyonu' },
  { value: 'KN', label: 'Saint Kitts ve Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (Fransız bölgesi)' },
  { value: 'PM', label: 'Saint Pierre ve Miquelon' },
  { value: 'VC', label: 'Saint Vincent ve Grenadinler' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'São Tomé ve Príncipe' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Sırbistan' },
  { value: 'SC', label: 'Seyşeller' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapur' },
  { value: 'SX', label: 'Sint Maarten (Hollanda bölgesi)' },
  { value: 'SK', label: 'Slovakya' },
  { value: 'SI', label: 'Slovenya' },
  { value: 'SB', label: 'Solomon Adaları' },
  { value: 'SO', label: 'Somali' },
  { value: 'ZA', label: 'Güney Afrika' },
  { value: 'GS', label: 'Güney Georgia ve Güney Sandwich Adaları' },
  { value: 'SS', label: 'Güney Sudan' },
  { value: 'ES', label: 'İspanya' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'SH', label: 'Saint Helena, Ascension ve Tristan da Cunha' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Surinam' },
  { value: 'SJ', label: 'Svalbard ve Jan Mayen' },
  { value: 'SE', label: 'İsveç' },
  { value: 'CH', label: 'İsviçre' },
  { value: 'SY', label: 'Suriye Arap Cumhuriyeti' },
  { value: 'TW', label: 'Çin Cumhuriyeti (Tayvan)' },
  { value: 'TJ', label: 'Tacikistan' },
  { value: 'TZ', label: 'Tanzanya, Birleşik Cumhuriyeti' },
  { value: 'TH', label: 'Tayland' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad ve Tobago' },
  { value: 'TN', label: 'Tunus' },
  { value: 'TR', label: 'Türkiye' },
  { value: 'TM', label: 'Türkmenistan' },
  { value: 'TC', label: 'Turks ve Caicos Adaları' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukrayna' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Özbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VA', label: 'Vatikan Şehri' },
  { value: 'VE', label: 'Venezuela Bolivarcı Cumhuriyeti' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'WF', label: 'Wallis ve Futuna' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambiya' },
  { value: 'ZW', label: 'Zimbabve' }
]

export function getCountryList(locale) {
  const LOCALE_TO_COUNTRY = {
    "de": COUNTRY_DE,
    "en": COUNTRY_EN,
    "tr": COUNTRY_TR
  };
  return LOCALE_TO_COUNTRY[locale] || LOCALE_TO_COUNTRY.de;
}

export const amazonMarketplaces = [
  { value: 'A1PA6795UKMFR9', label: 'DE' },
  { value: 'A1F83G8C2ARO7P', label: 'UK' },
  { value: 'ATVPDKIKX0DER', label: 'US' },
  { value: 'A1RKKUPIHCS9HS', label: 'ES' },
  { value: 'A13V1IB3VIYZZH', label: 'FR' },
  { value: 'A1VC38T7YXB528', label: 'IT' },
  { value: 'AMEN7PMS3EDWL', label: 'BE' },
  { value: 'A1805IZSGTT6HS', label: 'NL' },
  { value: 'A2NODRKZP88ZB9', label: 'SE' },
  { value: 'A33AVAJ2PDY3EV', label: 'TR' },
  { value: 'A17E79C6D8DWNP', label: 'SA' },
  { value: 'A2VIGQ35RCS4UG', label: 'AE' },
];

export const CURRENCY_OPTIONS_DE = [
  {
    value: 'EUR',
    label: '€ - Euro',
    name: 'Euro',
    symbol: '€'
  },
  {
    value: 'USD',
    label: '$ - US-Dollar',
    name: 'US-Dollar',
    symbol: '$'
  },
  {
    value: 'GBP',
    label: '£ - Britisches Pfund',
    name: 'Britisches Pfund',
    symbol: '£'
  },
  {
    value: 'TRY',
    label: '₺ - Türkische Lira',
    name: 'Türkische Lira',
    symbol: '₺'
  },
  {
    value: 'SEK',
    label: 'kr - Schwedische Krone',
    name: 'Schwedische Krone',
    symbol: 'kr'
  },
  {
    value: 'CHF',
    label: 'CHF - Schweizer Franken',
    name: 'Schweizer Franken',
    symbol: 'CHF'
  },
  {
    value: 'CAD',
    label: 'C$ - Kanadischer Dollar',
    name: 'Kanadischer Dollar',
    symbol: 'C$'
  },
  {
    value: 'PLN',
    label: 'zł - Polnischer Zloty',
    name: 'Polnischer Zloty',
    symbol: 'zł'
  },
];

export const CURRENCY_OPTIONS_EN = [
  {
    value: 'EUR',
    label: '€ - Euro',
    name: 'Euro',
    symbol: '€'
  },
  {
    value: 'USD',
    label: '$ - USA Dollar',
    name: 'USA Dollar',
    symbol: '$'
  },
  {
    value: 'GBP',
    label: '£ - British Pound',
    name: 'British Pound',
    symbol: '£'
  },
  {
    value: 'TRY',
    label: '₺ - Turkish Lira',
    name: 'Turkish Lira',
    symbol: '₺'
  },
  {
    value: 'SEK',
    label: 'kr - Swedish Krona',
    name: 'Swedish Krona',
    symbol: 'kr'
  },
  {
    value: 'CHF',
    label: 'CHF - Swiss Franc',
    name: 'Swiss Franc',
    symbol: 'CHF'
  },
  {
    value: 'CAD',
    label: 'C$ - Canadian Dollar',
    name: 'Canadian Dollar',
    symbol: 'C$'
  },
  {
    value: 'PLN',
    label: 'zł - Polish Zloty',
    name: 'Polish Zloty',
    symbol: 'zł'
  },
];

export const CURRENCY_OPTIONS_TR = [
  {
    value: 'EUR',
    label: '€ - Euro',
    name: 'Euro',
    symbol: '€'
  },
  {
    value: 'USD',
    label: '$ - Amerikan Doları',
    name: 'Amerikan Doları',
    symbol: '$'
  },
  {
    value: 'GBP',
    label: '£ - İngiliz Sterlini',
    name: 'İngiliz Sterlini', 
    symbol: '£'
  },
  {
    value: 'TRY',
    label: '₺ - Türk Lirası',
    name: 'Türk Lirası',
    symbol: '₺'
  },
  {
    value: 'SEK',
    label: 'kr - İsveç Kronu',
    name: 'İsveç Kronu',
    symbol: 'kr'
  },
  {
    value: 'CHF',
    label: 'CHF - İsviçre Frangı',
    name: 'İsviçre Frangı',
    symbol: 'CHF'
  },
  {
    value: 'CAD',
    label: 'C$ - Kanada Doları',
    name: 'Kanada Doları',
    symbol: 'C$'
  },
  {
    value: 'PLN',
    label: 'zł - Polonya Zlotisi',
    name: 'Polonya Zlotisi',
    symbol: 'zł'
  },
];

export function getCurrencyList(locale) {
  const LOCALE_TO_CURRENCY = {
    "de": CURRENCY_OPTIONS_DE,
    "en": CURRENCY_OPTIONS_EN,
    "tr": CURRENCY_OPTIONS_TR
  };
  return LOCALE_TO_CURRENCY[locale] || LOCALE_TO_CURRENCY.de;
}