import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { getCountryList, NoRecordsFoundMessage, headerSortingClasses, sortCaret } from '_metronic/_helpers';
import { allQueryKeys } from '../../reports-users/_context/ReportUIHelper';
import { RemotePagination } from '_metronic/_partials/controls';
import { useLang } from '_metronic/i18n';
import { useIntl } from 'react-intl';



export default function UserContactTable({ UIProps, contact, loading, count, btnRef }) {

  const locale = useLang();
  const { formatMessage: intl } = useIntl();

  const COLUMNS = [
    {
      dataField: '#',
      text: intl({ id: 'GENERAL.NO' }),
      isDummyField: true,
      headerStyle: { minWidth: '40px', width: "5%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bolder text-dark-50',
      editable: false,
      formatter: (cell, row, rowIndex) => ((UIProps.queryParams.pagination.page - 1) * (UIProps.queryParams.pagination.limit || 10) + rowIndex + 1),
    },
    {
      dataField: 'owner',
      text: intl({ id: 'GENERAL.OWNER' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold text-nowrap',
      headerStyle: { minWidth: "140px" },
    },
    {
      dataField: 'company_name',
      text: intl({ id: 'GENERAL.COMPANY' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold text-nowrap',
      headerStyle: { minWidth: "120px" },
    },
    {
      dataField: 'email',
      text: intl({ id: 'GENERAL.EMAIL' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold text-nowrap',
      headerStyle: { minWidth: "120px" },
      formatter: (cell, row) => (<a href={`mailto:${cell}`} className="text-decoration-none text-dark text-hover-primary">{cell}</a>)
    },
    {
      dataField: 'phone',
      text: intl({ id: 'GENERAL.PHONE' }),
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold text-nowrap',
      headerStyle: { minWidth: "120px" },
    },
    {
      dataField: 'mobile',
      text: intl({ id: 'GENERAL.MOBILE' }),
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold text-nowrap',
      headerStyle: { minWidth: "120px" },
    },
    {
      dataField: 'address.city',
      text: intl({ id: 'GENERAL.ADDRESS' }),
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold text-nowrap',
      headerStyle: { minWidth: '160px' },
      formatter: (cell, row) => {
        const location = [];
        if (row.address?.country_code) {
          location.push(row.address?.country_code);
        }
        if (row.address?.post_code) {
          location.push(row.address?.post_code);
        }
        return (
          <div className='d-flex flex-column mr-1'>
            <span className='font-weight-bold text-nowrap'>{row.address?.city}</span>
            {/* <span className='font-weight-bold text-nowrap'>{row.address?.address_line1}</span> */}
            <span className='text-dark-50'>{location.join('-')}</span>
          </div>
        );
      },
    },
    {
      dataField: 'address.country_code',
      text: intl({ id: 'ADDRESS.COUNTRY' }),
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold text-nowrap',
      headerStyle: { minWidth: '160px' },
      formatter: (cell, row) => <span>{getCountryList(locale).find(code => code.value === row.address?.country_code)?.label}</span>
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={contact || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        bordered={false}
        striped
        condensed
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => { UIProps.setFilterQuery('DELETE_PARAMS', allQueryKeys) }} />}

      />
      <RemotePagination
        queryParams={UIProps.queryParams.pagination}
        setQueryParams={UIProps.setPaginationQuery}
        totalCount={count}
        entitiesCount={contact?.length}
        loading={loading}
      />
    </>
  );
}

