import React, { useEffect, useState, useRef, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { BackButton, SaveButton } from '_metronic/_partials';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';
import { prepareEbayInventoryListing } from '../EbayListingsUIHelper';
import { toast } from 'react-toastify';
import { ListingActions } from '../../_redux/actions';
import { EditErrorDialog } from './EditErrorDialog';
import { FormikHandler } from './FormikHandler';
import { ModuleRoutes } from 'constants/moduleRoutes';
import EbayGeneral from './general/EbayGeneral';
import EbayVariant from './variants/EbayVariant';
import EbayCategory from './categories/EbayCategory';
import EbayPolicies from './policies/EbayPolicies';
import { getLastSearch } from '_metronic/_helpers';



export function EbayListingsEdit({ history, match: { params: { id, market_id, method } } }) {

  const dispatch = useDispatch();
  const lastSearch = getLastSearch()

  const UIContext = useProductListingsUIContext();
  const UIProps = useMemo(() => ({
    categoryInputValue: UIContext.categoryInputValue,
    selectedCategoryAspects: UIContext.selectedCategoryAspects,
  }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [UIContext.categoryInputValue, UIContext.selectedCategoryAspects]
  );

  const { productForEdit, categorySuggestions, actionTracker } = useSelector(state => ({
    productForEdit: state.listings.productForEdit,
    categorySuggestions: state.listings.categorySuggestions,
    actionTracker: state.listings.actionTracker,
    state: state.listing,
  }),
    shallowEqual
  );

  const [openErrorDialog, setOpenErrorDialog] = useState({ show: false, errors: [] });
  const [isVariant, setIsVariants] = useState([]);
  const closeErrorDialog = () => setOpenErrorDialog({ show: false, errors: [] });

  useEffect(() => {
    if (id && market_id) {
      dispatch(ListingActions.ebayInventoryListingsPreparationRequest(id, market_id, method));
    };
  }, [id, dispatch, market_id, method]);

  useEffect(() => {
    if (method === 'new' && productForEdit.name) {
      dispatch(ListingActions.ebayInventoryListingsCategory(market_id, productForEdit.name));
    }
    const listing = productForEdit?.ebayInventory;

    if (method === 'edit') {
      if (listing && listing?.category?.id !== "" && listing?.category?.path === "" && listing?.category?.name === "") {
        dispatch(ListingActions.ebayInventoryListingsCategory(market_id, productForEdit.name));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productForEdit?.name, id, dispatch, market_id, method]);


  // Clean redux state after component unmount
  useEffect(() => {
    return () => {
      dispatch(ListingActions.cleanUpReducer({ productForEdit: {}, categorySuggestions: [], isLoading: false, isLoaded: false }));
    };
  }, [dispatch]);


  const btnRef = useRef();
  const saveClickButton = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const handleDeletedRowChange = (deletedRow) => setIsVariants(deletedRow);

  const onSubmitHandler = values => {

    const hasRequiredAspects = UIProps.selectedCategoryAspects.filter(asp => asp.isRequired && asp.value === '');
    if (hasRequiredAspects.length > 0) {
      toast.error(<FormattedMessage id="PRODUCT_LISTINGS.EBAY.REQUIRED_ASPECTS" />);
      return;
    }
    let aspects = {};
    for (const aspect of UIProps.selectedCategoryAspects.filter(asp => asp.value !== '')) {
      aspects = { ...aspects, [aspect.name.toString()]: [aspect.value] };
    };

    values.variants = values.variants.filter(variant => !isVariant.includes(variant.id));

    const [preparedInventory, error] = prepareEbayInventoryListing({ values, aspects, productId: id });
    if (Array.isArray(error) && error.length > 0) {
      setOpenErrorDialog({ show: true, errors: error });
      // error.forEach(err => toast.error(`<${err.field}> => ` + err.message));
      return;
    }

    const newEbayListing = {
      market: 'Ebay',
      market_id,
      status: 'DRAFT',
      isGrouped: preparedInventory.isGrouped,
      shopValue: preparedInventory.shopValue,
      ...(!preparedInventory.isGrouped && { inventory: preparedInventory.inventory }),
      ...(preparedInventory.isGrouped && { inventoryGroupItem: preparedInventory.inventoryGroupItem }),
      ...(preparedInventory.isGrouped && { groupIds: [values.sku] }),
      ...(preparedInventory.isGrouped && { variantInventories: preparedInventory.variantInventories }),
      suggestions: categorySuggestions,
      merchantLocations: { selectedKey: values.merchantLocationKey, locations: values.merchantLocations },
      category: { id: UIProps.categoryInputValue.value, path: UIProps.categoryInputValue.label, name: UIProps.categoryInputValue.label.split(' > ').pop() },
      listingPolicies: values.listingPolicies,
    };

    dispatch(ListingActions.ebayCreateOrReplaceInventoryRequest(id, newEbayListing, method));
  };


  if (actionTracker.isSuccess) {
    const path = ModuleRoutes.EBAY_LISTINGS_FN(market_id) + lastSearch;
    return <Redirect to={path} />;
  }


  return (
    <>
      <EditErrorDialog show={openErrorDialog.show} onHide={closeErrorDialog} errors={openErrorDialog.errors} />

      <Card className="card-box">
        <CardHeader
          back={<BackButton  link={ModuleRoutes.EBAY_LISTINGS_FN(market_id) + lastSearch}  />}
          title={<FormattedMessage id="PRODUCT.EBAY_TITLE" />}
          id='title_ebay_edit'
          sticky
        >
          <CardHeaderToolbar>
            <SaveButton onClick={saveClickButton} />
          </CardHeaderToolbar>
        </CardHeader>

        <CardBody>
          <FormikHandler initialValues={productForEdit} onSubmitHandler={onSubmitHandler} btnRef={btnRef}>
            <EbayGeneral productId={id} />
            <EbayVariant onDeletedRowChange={handleDeletedRowChange} market_id={market_id} method={method} />
            <EbayPolicies market_id={market_id} />
            <EbayCategory method={method} market_id={market_id} />
          </FormikHandler>
        </CardBody>

      </Card>
    </>
  );
}
