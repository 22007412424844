import React from "react";
import { useHistory } from "react-router-dom";
import { ModuleRoutes } from "constants/moduleRoutes";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { Icon } from '_metronic/_icons';



export function CustomersGrouping({ UIProps }) {

  const history = useHistory();

  const deleteAllSelectedCustomer = () => {
    const ids = UIProps.ids.join("&");
    history.push(ModuleRoutes.DELETE_CUSTOMER_LISTS_FN(ids))
  };


  return (
    <>

      <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-auto mr-2"
        onClick={() => UIProps.openExportCustomerDialog()}
        disabled={!UIProps.ids || UIProps.ids.length <= 0}
        style={{ cursor: !UIProps.ids || (UIProps.ids.length <= 0 && 'not-allowed') }}
        id='btn_export'
      >
        <Icon.CloudDownload />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="IMPORT_EXPORT.EXPORT" />
        </span>

      </Button>

      <Button variant="light-primary" type="button" className="svg-icon svg-icon-sm font-weight-bold" onClick={deleteAllSelectedCustomer} disabled={UIProps.ids.length <= 0} id="btn_multiple_delete">
        <Icon.Trash />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="GENERAL.DELETE" />
        </span>
      </Button>

    </>
  );
}
