/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

/** 
 * Please be familiar with react-bootstrap-table-next column formaters
 * https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
 */

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { checkEbayInventorySync } from '../../EbayListingsUIHelper';
import { convertPriceCurrency } from '_metronic/_helpers';
import { FormattedMessage } from 'react-intl';
import { BASE_URL } from 'constants/apiUrl';
import { Icon } from '_metronic/_icons';



export function PrepareColumnFormatter(cellContent, row, rowIndex, { /* market_id, publishEbayInventoryHandler, */ openSyncAlertDialog }) {

  const errors = checkEbayInventorySync(row);
  const isPublished = row?.inventories?.some(item => item.status === 'PUBLISHED') ?? false;
  if (errors.length > 0) {
    return (
      <OverlayTrigger
        overlay={<Tooltip id="ebay-listing-sync-tooltip"><FormattedMessage id="GENERAL.WARNING" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-warning btn-sm" onClick={() => openSyncAlertDialog(row.id, errors, isPublished)} id={`btn_sync_${row?.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.ExclamationTriangle />
          </span>
        </a>
      </OverlayTrigger>
    );
  } else {
    return (
      <OverlayTrigger
        overlay={<Tooltip id="ebay-listing-sync-tooltip"><FormattedMessage id="GENERAL.READY" /></Tooltip>}>
        <span className="svg-icon svg-icon-md">
          <Icon.CheckSquare className={"text-success"} />
        </span>
      </OverlayTrigger>
    );
  }

  /*   const ebayInventory = Array.isArray(row.inventories) && row.inventories.length > 0 && row.inventories.find(ei => ei.market_id === market_id);
    const status = ebayInventory && ebayInventory.status;
    const isWithdrawable = status === 'PUBLISHED' && (ebayInventory.offerId || ebayInventory.groupIds);
    return (
      <>
        {status && ebayInventory?.merchantLocations?.selectedKey != null &&
          <OverlayTrigger overlay={<Tooltip id="ebay-listing-edit-tooltip">{isWithdrawable ? <FormattedMessage id="PRODUCT_LISTINGS.EBAY_WITHDRAW" /> : <FormattedMessage id='GENERAL.UPLOAD' />}</Tooltip>}>
            <a className={`btn btn-icon btn-sm ${isWithdrawable ? "btn-hover-danger" : "btn-hover-info"}`}
              onClick={() => publishEbayInventoryHandler({ ids: [row.id], market_id: market_id, method: isWithdrawable ? 'withdraw' : 'publish' })}
            >
              <span className="svg-icon svg-icon-md">
                {isWithdrawable ? <Icon.BoxArrowDown /> : <Icon.BoxArrowUp />}
              </span>
            </a>
          </OverlayTrigger>
        }
      </>
    ) */
}


export function ActionsColumnFormatter(cellContent, row, rowIndex, { openPrepareEbayListingEdit, market_id, deleteEbayInventoryHandler }) {

  const ebayInventory = Array.isArray(row?.inventories) && row.inventories.length > 0 && row.inventories.find(ei => ei.market_id === market_id);
  const status = ebayInventory && ebayInventory.status;
  const errors = checkEbayInventorySync(row);

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="ebay-listing-edit-tooltip"><FormattedMessage id="GENERAL.PREPARE" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-primary btn-sm"
          style={errors.length === 0 && status !== 'PUBLISHED' ? null : { pointerEvents: "none", opacity: 0.5 }}
          onClick={() => openPrepareEbayListingEdit(market_id, row.id, ebayInventory ? 'edit' : 'new')}
          id={`btn_prepare_${row?.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.PencilSquare />
          </span>
        </a>
      </OverlayTrigger>

      <OverlayTrigger
        overlay={<Tooltip id="ebay-listing-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-danger btn-sm"
          style={(status && status === 'DRAFT') ? null : { pointerEvents: "none", opacity: 0.5 }}
          onClick={() => deleteEbayInventoryHandler(row.id, market_id, row?.inventories ?? [])}
          id={`btn_delete_${row?.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Trash />
          </span>
        </a>
      </OverlayTrigger>

    </>
  );

}



export function NameColumnFormatter(cellContent, row, rowIndex, { openPrepareEbayListingEdit, openProductDetailsDialog, market_id, }) {


  const ebayInventory = Array.isArray(row?.inventories) && row.inventories.length > 0 && row.inventories.find(ei => ei.market_id === market_id);
  const status = ebayInventory && ebayInventory.status;
  const errors = checkEbayInventorySync(row);

  const onClickHandler = (row) => {
    const listType = ebayInventory ? "edit" : "new";
    openPrepareEbayListingEdit(market_id, row.id, listType);
  };

  const isDisabled = errors.length === 0 && status !== 'PUBLISHED'

  return (
    <div className="d-flex align-items-center">
      <div type="button"
        className="symbol symbol-50 symbol-light mr-4"
        onClick={() => openProductDetailsDialog(row.id)} id='btn_product_picture'>
        <span className="symbol-label overflow-hidden">
          {row.images[0]?.thumbnail
            ? <img src={`${BASE_URL}/${row.images[0]?.thumbnail}`} className="h-100" alt={cellContent} />
            : <Icon.Image className="h-100 w-50" style={{ opacity: 0.3 }} />
          }
        </span>
      </div>

      <div>
        <div type={isDisabled ? "button" : ""}
          className={`font-weight-bolder text-dark-75 font-size-lg text-nowrap mb-1 ${isDisabled ? "text-hover-primary preview" : ""}`}
          onClick={() => isDisabled && onClickHandler(row)} id="btn_product_edit">
          <span>{row?.inventories?.find(item => item.market_id === market_id)?.shopValue?.name ?? cellContent}</span>
          <span className="text-dark-25 svg-icon svg-icon-sm invisible pl-2">
            <Icon.Pencil />
          </span>
        </div>
        <div className="font-weight-bold text-dark-50 text-nowrap">
          {row.sku}
        </div>
      </div>
    </div>
  )
}


export function QuantityColumnFormatter(cell, row, market_id) {

  const shopValue = row?.inventories?.find(item => item.market_id === market_id)?.shopValue;
  const newCell = shopValue?.quantity ?? cell;

  return <span>{newCell}</span>;
};


export function PricingColumnFormatter(cell, row, market_id) {

  const shopValue = row?.inventories?.find(item => item.market_id === market_id)?.shopValue;
  const newCell = shopValue?.pricing ?? cell.gross_price;

  if (shopValue?.variants?.length > 0 || (!shopValue && row.variants?.length > 0)) {
    return (
      <>
        <span className="label label-lg label-inline label-light-info ml-1">* ab</span>
        <span className="font-weight-bold">{convertPriceCurrency(+newCell, cell?.currency)}</span>
      </>
    )
  }
  return (convertPriceCurrency(+newCell, cell?.currency));
}