import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Card, CardBody, CardHeader } from "_metronic/_partials/controls";
import { useSearchQueryContext } from "./_context/SearchQueryProvider";
import { DigitalProductsFilter } from "./digital-products-filter/DigitalProductsFilter";
import { DigitalProductsTable } from "./digital-droducts-table/DigitalProductsTable";
import { BlankMessage } from "_metronic/_partials/components";
import { LoadingDialog } from "_metronic/_partials/loading";
import { digitalProductsActions } from "./_redux/actions";
import { Icon } from '_metronic/_icons';



export function DigitalProductsCard() {

  const dispatch = useDispatch();

  const { entities, loading, countDigitalProduct, totalDigitalProduct } = useSelector(state => ({
    entities: state.digitalProduct.entities,
    loading: state.digitalProduct.loading,
    countDigitalProduct: state.digitalProduct.countDigitalProduct,
    totalDigitalProduct: state.digitalProduct.totalDigitalProduct,
  }));


  const queryContext = useSearchQueryContext();
  const { queryParams, setOrderQuery, setPaginationQuery, setSearchQuery } = useMemo(() => ({
    queryParams: queryContext.queryParams,
    setOrderQuery: queryContext.setOrderQuery,
    setPaginationQuery: queryContext.setPaginationQuery,
    setSearchQuery: queryContext.setSearchQuery
  }), [queryContext]);

  useEffect(() => {
    let delayDebounceFn;
    if (queryParams.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(digitalProductsActions.getDigitalProducts(queryParams))
      }, 350);
    } else {
      dispatch(digitalProductsActions.getDigitalProducts(queryParams))
    }

    return () => { clearTimeout(delayDebounceFn) };
  }, [dispatch, queryParams]);


  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.Password />}
        title={<FormattedMessage id="PRODUCT.KEYS" />}
        className="display-5"
        id='title_digital_products'
      />

      <CardBody>
        {totalDigitalProduct === 0
          ? <BlankMessage
            icon={<Icon.Password />}
            // title={<FormattedMessage id='SHIPPING.BLANK_MESSAGE_TITLE' />}
            // message={<FormattedMessage id='SHIPPING.BLANK_MESSAGE' />}
            className='min-50-height'
            loading={loading}
          />
          : <>
            <DigitalProductsFilter setSearchQuery={setSearchQuery} />
            <DigitalProductsTable
              loading={loading}
              entities={entities}
              countDigitalProduct={countDigitalProduct}
              queryParams={queryParams}
              setPaginationQuery={setPaginationQuery}
              setOrderQuery={setOrderQuery}
              setSearchQuery={setSearchQuery}
            />
          </>
        }
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  );
}