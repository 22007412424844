import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, CardBody, CardSubHeader, SVReactSelect } from '_metronic/_partials/controls';
import { TAX_OPTIONS, UNIT_OPTIONS } from 'app/modules/products/_context/ProductsUIHelpers';
import { getCurrencyList } from '_metronic/_helpers/constant/optionData';
import { UserInfoDropdown } from '_metronic/_partials';
import { Row, Col } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import { useLang } from '_metronic/i18n';
import { Field } from 'formik';


export function StandardSettings() {

  const locale = useLang();
  const { formatMessage: intl } = useIntl();

  const unitOptions = UNIT_OPTIONS.map(item => ({
    value: item.value,
    label: intl({ id: item.label })
  }));

  const taxOptions = TAX_OPTIONS.map(item => ({
    value: item.value,
    label: intl({ id: item.label }) || item.label,
  }));

  const INPUT = [
    {
      name: "default_product.tax",
      options: taxOptions,
      label: "SETTINGS.PRODUCT.TAX_DEFAULT",
    },
    {
      name: "default_product.unit",
      options: unitOptions,
      label: "SETTINGS.PRODUCT.TAX_QUANTITY"
    },
    {
      name: "default_product.currency",
      options: getCurrencyList(locale),
      label: "SETTINGS.PRODUCT.CURRENCY"
    }
  ]


  return (
    <Card className='card-stretch gutter-b'>

      <CardSubHeader
        title={<FormattedMessage id="SETTINGS.PRODUCT.TAX_TITLE" />}
        icon={<Icon.Catalog />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='SETTINGS.PRODUCT.TAX_TITLE' />}
          description={<FormattedMessage id='USER_GUIDE.SETTINGS.PRODUCT_STANDARD' />} />}
      />

      <CardBody>
        <Row>

          <Col md="12" className="mb-5">
            <FormattedMessage id="SETTINGS.PRODUCT.ITEMS_TITLE" />
          </Col>

          {INPUT.map((item, i) => (
            <Col md="6" key={i}>
              <Field component={SVReactSelect}
                {...{
                  name: item.name,
                  label: intl({ id: item.label }),
                  options: item.options,
                  required: item.required,
                  withFeedbackLabel: true,
                  feedbackLabel: true
                }}
              />
            </Col>
          ))}

        </Row>
      </CardBody>

    </Card>
  );
}
