import React from 'react';
import { SVFormControl, SVReactSelect } from '_metronic/_partials/controls';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { maxValidation } from '_metronic/_helpers';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';



export const EditVariantForm = ({ initialValues, onSubmit, submitBtnRef, currentSpecs }) => {

  const { formatMessage: intl } = useIntl();
  const { values } = useFormikContext();

  if (!initialValues) {
    return (
      <div className="text-center my-30">
        <h3 className="text-dark-25">No variants found</h3>
      </div>
    );
  }

  const validationSchema = Yup.object().shape({
    sku: Yup.string()
      .strict(true)
      .trim(intl({ id: 'PRODUCT.GENERAL.SKU_TRIM' }))
      .required(intl({ id: 'PRODUCT.GENERAL.SKU_REQUIRED' }))
      .max(50, maxValidation('50')),
    quantity: Yup.number()
      .moreThan(-1, intl({ id: "PRODUCT.GENERAL.PRICE_THAN" }))
      .max(999999, maxValidation("999.999"))
      .test('is-integer-if-piece', intl({ id: "PRODUCT.GENERAL.INTEGER_REQUIRED" }), function (value) {
        if (values && values?.unit === 'piece' && value != null) { return Number.isInteger(value); }
        return true;
      })
      .test('is-valid-number', intl({ id: "PRODUCT.GENERAL.INVALID_NUMBER" }), function (value) {
        return value === null || /^\d+(\.\d+)?$/.test(value?.toString());
      })
      .nullable(true),
    ean: Yup.string()
      .max(13, maxValidation('13')),
    asin: Yup.string()
      .length(10, intl({ id: "PRODUCT.MORE_SETTINGS.ASIN_INFO" })),
    // .length(13, intl({ id: 'PRODUCT.GENERAL.EAN_LENGTH' })),
    // .matches(eanRegExp, intl({ id: 'PRODUCT.GENERAL.EAN_MATCHES' }))
    // .test('ean', intl({ id: 'PRODUCT.GENERAL.EAN_TEST' }), eanFunctions),
    gross_price: Yup.number()
      .min(0, intl({ id: 'PRODUCT.GENERAL.PRICE_THAN' }))
      .max(10000000, intl({ id: 'PRODUCT.GENERAL.PRICE_MAX' })),
    purchase_price: Yup.number()
      .min(0, intl({ id: 'PRODUCT.GENERAL.PRICE_THAN' }))
      .test('Is positive?', intl({ id: 'PRODUCT.GENERAL.PRICE_THAN' }), value => value >= 0)
      .max(10000000, intl({ id: 'PRODUCT.GENERAL.PRICE_MAX' })),
    // Adding dynamic validation for each selected variant spec
    ...currentSpecs.reduce((acc, curr) => {
      acc[curr.value] = Yup.string().required(intl({ id: 'PRODUCT.GENERAL.SPEC_REQUIRED' }));
      return acc;
    }, {}),
  })

  const INPUTS = [
    {
      name: "sku",
      type: "text",
      label: "GENERAL.SKU",
      required: true
    },
    {
      name: "ean",
      type: "text",
      label: "PRODUCT.MORE_SETTINGS.EAN",
    },
    {
      name: "asin",
      type: "text",
      label: "PRODUCT.MORE_SETTINGS.ASIN",
    },
    {
      name: "quantity",
      type: "number",
      label: "PRODUCT.GENERAL.QUANTITY",
    },
    {
      name: "purchase_price",
      type: "number",
      label: "PRODUCT.GENERAL.PURCHASE",
    },
    {
      name: "gross_price",
      type: "number",
      label: "PRODUCT.GENERAL.SALE_PRICE_GROSS",
    },
    ...currentSpecs.map(spec => ({
      name: spec.value,
      component: SVReactSelect,
      label: spec.label,
      options: spec.aspects
    }))

  ];

  return (
    <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form >

          <Row>
            {INPUTS.map((item, i) => (
              <Col lg={item.size || '12'} key={i}>
                <Field component={item.component || SVFormControl}
                  {...{
                    name: item.name,
                    type: item.type,
                    label: intl({ id: item.label }),
                    options: item.options,
                    withFeedbackLabel: true,
                    feedbackLabel: true,
                    required: item.required,
                  }}
                />
              </Col>
            ))}
          </Row>

          <button type="submit" className='d-none' ref={submitBtnRef} onClick={handleSubmit} />
        </Form>
      )}
    </Formik>
  );
};
