import { call, put, select, takeEvery } from "redux-saga/effects";
import { dashboardMiddleware } from "middlewares";
import { AuthActions } from "app/pages/auth/_redux/actions";
import { DashboardActions } from "./actions";
import ActionTypes from "./actionTypes";



// Get Dashboard Saga
function* getDashboard({ payload: { queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(dashboardMiddleware.getDashboardData, accessToken, queryParams);
    yield put(DashboardActions.getDashboardSuccess(response.data));

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(DashboardActions.getDashboardFail(err));
  }
}


export function* dashboardSaga() {
  yield takeEvery(ActionTypes.GET, getDashboard);
}
