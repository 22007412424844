import React, { useContext, createContext, useState, useCallback } from 'react';
import { UIDates } from '_metronic/_helpers';
import { useSearchQuery } from 'app/hooks';
import { useIntl } from 'react-intl';



const UserUIContext = createContext({});
export const useReportsUserUIContext = () => useContext(UserUIContext);


export const UserUIProvider = ({ children }) => {

  const { formatMessage: intl } = useIntl()
  const [ids, setIds] = useState([]);
  const [userId, setUserId] = useState();

  // query params
  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    search: '',
    filter: { status: 'active' },
  });

  const setQueryParams = useCallback((params) => setQueryBase(params), [])

  const { setSearchQuery, setFilterQuery, setPaginationQuery, setOrderQuery } = useSearchQuery(setQueryParams);

  const initialDateRange = {
    date_from: UIDates.dayCalculation(-30),
    date_to: UIDates.getCurrentDate(),
    label: intl({ id: "DASHBOARD.DATE.LAST_30_DAYS" })
  };

  const [dateRange, setDateRange] = useState(
    initialDateRange
  );
  const changeDateRange = (date = initialDateRange) => setDateRange(date);

 //* User details dialog
 const [showUserDetailsDialog, setShowUserDetailsDialog] = useState(false);
 const openUserDetailsDialog = id => {
  setUserId(id);
  setShowUserDetailsDialog(true);
 };
 const closeUserDetailsDialog = () => {
  setUserId(undefined);
  setShowUserDetailsDialog(false);
 };


  const value = {
    ids,
    userId,
    setUserId,
    setIds,
    queryParams,
    setSearchQuery,
    setFilterQuery,
    setPaginationQuery,
    setOrderQuery,
    changeDateRange,
    dateRange,
    setDateRange,

    showUserDetailsDialog,
    closeUserDetailsDialog,
    openUserDetailsDialog,
  };


  return (
    <UserUIContext.Provider value={value}>
      {children}
    </UserUIContext.Provider>
  );
};