import React from 'react'
import { useDispatch } from "react-redux";
import { Card, CardBody, CardSubHeader, CardHeaderToolbar, RemotePagination } from '_metronic/_partials/controls'
import { headerSortingClasses, NoRecordsFoundMessage, sortCaret, UIDates } from '_metronic/_helpers';
import { BlankMessage } from "_metronic/_partials/components";
import { importExportActions } from '../../_redux/actions';
import { allQueryKeys } from '../../_context/ImportExportUIHelper';
import * as columnFormatters from './ActionsColumnFormatter';
import { ImportFilter } from '../import-filter/ImportFilter';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormattedMessage, useIntl } from 'react-intl'
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';



export default function ImportTable({ importData, loading, UIProps, count, totalCount }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();
  const locale = useLang();

  const importDownload = (row) => dispatch(importExportActions.importExportDownload(row, locale));
  const importDelete = (id) => dispatch(importExportActions.importExportDelete(id, "import", UIProps.queryParams));

  const COLUMNS = [
    {
      dataField: 'module',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-left pl-7',
      classes: 'pl-0 pl-md-5 py-3',
      headerStyle: { minWidth: '100px' },
      style: { height: '50px' },
      formatter: columnFormatters.NameColumnFormatter,
      formatExtraData: {
        importDownload,
      }
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'IMPORT_EXPORT.IMPORT.AT' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'text-center',
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '180px' },
      formatter: (cell, row) => (
        <div className="text-dark-50 font-weight-bold">
          {UIDates.dateFormatter(cell)}
        </div>
      ),
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      headerStyle: { minWidth: '150px', width: "10%" },
      headerClasses: 'text-center',
      classes: 'text-center',
      editable: false,
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        importDownload,
        importDelete,
      },
    },
  ];

  return (
    <Card className='gutter-b'>

      <CardSubHeader title={<FormattedMessage id='IMPORT_EXPORT.IMPORT_LIST' />} icon={<Icon.TextIndentLeft />} >

        <CardHeaderToolbar>
          <ImportFilter UIProps={UIProps} />
        </CardHeaderToolbar>
      </CardSubHeader>

      <CardBody>
        {totalCount === 0 && !loading
          ? <BlankMessage
            icon={<Icon.TextIndentLeft />}
            title={<FormattedMessage id='IMPORT_EXPORT.IMPORT.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='IMPORT_EXPORT.IMPORT.BLANK_MESSAGE' />}
            className="my-10"
            loading={loading}
          />
          : <>
            <BootstrapTable
              keyField="id"
              data={importData || []}
              columns={COLUMNS}
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-vertical-center table-sm overflow-hidden"
              bootstrap4
              bordered={false}
              condensed
              striped
              noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => { UIProps.setFilterQuery('DELETE_PARAMS', allQueryKeys); }} />}
            />
            {importData.length ? <RemotePagination
              queryParams={UIProps.queryParams?.pagination}
              setQueryParams={UIProps.setPaginationQuery}
              totalCount={count}
              entitiesCount={importData?.length}
              loading={loading}
            /> : null}
          </>

        }
      </CardBody>
    </Card>
  )
}
